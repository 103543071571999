import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import {
    users as usersData,
    countries as countriesData,
    projects as projectsData,
    districts as districtsData
} from 'app/mock-api/apps/users/data';
import { assign, cloneDeep } from 'lodash-es';
import { from, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersMockApi {
    private _users: any[] = usersData;
    private _countries: any[] = countriesData;
    private _projects: any[] = projectsData;
    private _districts: any[] = districtsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Users - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/apps/users/all').reply(() => {
            // Clone the users
            const users = cloneDeep(this._users);

            // Sort the users by the name field by default
            users.sort((a, b) => a.name.localeCompare(b.name));

            // Return the response
            return [200, users];
        });

        // -----------------------------------------------------------------------------------------------------
        // @ Users Search - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/users/search')
            .reply(({ request }) => {
                // Get the search query
                const query = request.params.get('query');

                // Clone the users
                let users = cloneDeep(this._users);

                // If the query exists...
                if (query) {
                    // Filter the users
                    users = users.filter(
                        (user) =>
                            user.name &&
                            user.name
                                .toLowerCase()
                                .includes(query.toLowerCase())
                    );
                }

                // Sort the users by the name field by default
                users.sort((a, b) => a.name.localeCompare(b.name));

                // Return the response
                return [200, users];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ User - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/users/user')
            .reply(({ request }) => {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the users
                const users = cloneDeep(this._users);

                // Find the user
                const user = users.find((item) => item.id === id);

                // Return the response
                return [200, user];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ User - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/users/user')
            .reply(() => {
                // Generate a new user
                const newUser = {
                    id: FuseMockApiUtils.guid(),
                    avatar: null,
                    name: 'New User',
                    emails: [],
                    phoneNumbers: [],
                    job: {
                        title: '',
                        company: '',
                    },
                    birthday: null,
                    address: null,
                    notes: null,
                    projects: [],
                };

                // Unshift the new user
                this._users.unshift(newUser);

                // Return the response
                return [200, newUser];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ User - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/users/user')
            .reply(({ request }) => {
                // Get the id and user
                const id = request.body.id;
                const user = cloneDeep(request.body.user);

                // Prepare the updated user
                let updatedUser = null;

                // Find the user and update it
                this._users.forEach((item, index, users) => {
                    if (item.id === id) {
                        // Update the user
                        users[index] = assign({}, users[index], user);

                        // Store the updated user
                        updatedUser = users[index];
                    }
                });

                // Return the response
                return [200, updatedUser];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ User - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/users/user')
            .reply(({ request }) => {
                // Get the id
                const id = request.params.get('id');

                // Find the user and delete it
                this._users.forEach((item, index) => {
                    if (item.id === id) {
                        this._users.splice(index, 1);
                    }
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Countries - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/users/countries')
            .reply(() => [200, cloneDeep(this._countries)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Projects - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/users/projects')
            .reply(() => [200, cloneDeep(this._projects)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Projects - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/users/project')
            .reply(({ request }) => {
                // Get the project
                const newProject = cloneDeep(request.body.project);

                // Generate a new GUID
                newProject.id = FuseMockApiUtils.guid();

                // Unshift the new project
                this._projects.unshift(newProject);

                // Return the response
                return [200, newProject];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Projects - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/users/project')
            .reply(({ request }) => {
                // Get the id and project
                const id = request.body.id;
                const project = cloneDeep(request.body.project);

                // Prepare the updated project
                let updatedProject = null;

                // Find the project and update it
                this._projects.forEach((item, index, projects) => {
                    if (item.id === id) {
                        // Update the project
                        projects[index] = assign({}, projects[index], project);

                        // Store the updated project
                        updatedProject = projects[index];
                    }
                });

                // Return the response
                return [200, updatedProject];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Project - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/users/project')
            .reply(({ request }) => {
                // Get the id
                const id = request.params.get('id');

                // Find the project and delete it
                this._projects.forEach((item, index) => {
                    if (item.id === id) {
                        this._projects.splice(index, 1);
                    }
                });

                // Get the users that have the project
                const usersWithProject = this._users.filter(
                    (user) => user.projects.indexOf(id) > -1
                );

                // Iterate through them and delete the project
                usersWithProject.forEach((user) => {
                    user.projects.splice(user.projects.indexOf(id), 1);
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Avatar - POST
        // -----------------------------------------------------------------------------------------------------

        /**
         * Read the given file as mock-api url
         *
         * @param file
         */
        const readAsDataURL = (file: File): Promise<any> =>
            // Return a new promise
            new Promise((resolve, reject) => {
                // Create a new reader
                const reader = new FileReader();

                // Resolve the promise on success
                reader.onload = (): void => {
                    resolve(reader.result);
                };

                // Reject the promise on error
                reader.onerror = (e): void => {
                    reject(e);
                };

                // Read the file as the
                reader.readAsDataURL(file);
            });
        this._fuseMockApiService
            .onPost('api/apps/users/avatar')
            .reply(({ request }) => {
                // Get the id and avatar
                const id = request.body.id;
                const avatar = request.body.avatar;

                // Prepare the updated user
                let updatedUser: any = null;

                // In a real world application, this would return the path
                // of the saved image file (from host, S3 bucket, etc.) but,
                // for the sake of the demo, we encode the image to base64
                // and return it as the new path of the uploaded image since
                // the src attribute of the img project works with both image urls
                // and encoded images.
                return from(readAsDataURL(avatar)).pipe(
                    map((path) => {
                        // Find the user and update it
                        this._users.forEach((item, index, users) => {
                            if (item.id === id) {
                                // Update the avatar
                                users[index].avatar = path;

                                // Store the updated user
                                updatedUser = users[index];
                            }
                        });

                        // Return the response
                        return [200, updatedUser];
                    })
                );
            });
    }
}
