import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const PageAccessGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);

    // Check the page access status
    return inject(AuthService)
        .pageAccess(state.url)
        .pipe(
            switchMap((canAccess) => {
                if (!canAccess) {
                    const urlTree = router.parseUrl('');

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
};
