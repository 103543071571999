import { Capacitor } from '@capacitor/core';
import {  Component, NgZone } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { App , URLOpenListenerEvent} from '@capacitor/app';
import { KeyboardService } from './core/keyboard/keyboard.service';
import * as LiveUpdates from '@capacitor/live-updates';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private _location: Location,
    private _fuseConfirmationService: FuseConfirmationService,
    private zone: NgZone,
    private router: Router,
    private keyboardService: KeyboardService

  ) {
    this.initializeApp();

    StatusBar.setBackgroundColor({ color: '#ffffff' });
    StatusBar.setOverlaysWebView({
      overlay: false,
    })
    StatusBar.setStyle({
      style: Style.Light,
    })

    this.showStatusBar();
  }
  ngOnInit(): void {

  }

  showStatusBar = async () => {
    await StatusBar.show();
  };
  
  private handleDeepLink(urlString: string) {
    const url = new URL(urlString);
    // Handle your deep link routing here
    this.router.navigateByUrl(url.pathname + url.search);
  }

  
  async initializeApp() {
    // await this.updateApp()

   // Handle deep linking
   App.addListener('appUrlOpen', (data: { url: string }) => {
    this.handleDeepLink(data.url);
  });


    this.platform.ready().then(() => {
      StatusBar.setBackgroundColor({ color: '#ffffff' });
      
      // const setStatusBarStyleLight = async () => {
      //   await 
      // };
      // setStatusBarStyleLight()
      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        console.log('Back press handler!');
        if (this._location.isCurrentPathEqualTo('/example')) {
          // Show Exit Alert!
          this.showExitConfirm();
          processNextHandler();
        } else {
          // Navigate to back page
          console.log('Navigate to back page');
          this._location.back();
        }
      });

    });

  }

  async updateApp(){
      // Register event to fire each time user resumes the app  
  App.addListener('resume', async () => {
    if (localStorage.shouldReloadApp === 'true') {
      await LiveUpdates.reload();
    }
    else {
      const result = await LiveUpdates.sync();
      localStorage.shouldReloadApp = result.activeApplicationPathChanged;
    }
  });

  // First sync on app load
  const result = await LiveUpdates.sync();
  localStorage.shouldReloadApp = result.activeApplicationPathChanged;
  }


  showExitConfirm(){
    const dialogRef = this._fuseConfirmationService.open({
      dismissible:false,
      title: 'App Exit',
      message: `Do you want to close the app?`,
      actions:{
      cancel:{
        label:'Stay'
      },
      confirm:{
        label:'Exit'
      }
      }
      
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const exitApp = async () => {
          await App.exitApp();
        };

        exitApp()
      } else {
        // Submit without saving
        console.log('Application exit prevented!');
      }
    });
  }
 
}