import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  constructor(private platform: Platform) {
    this.initializeKeyboard();
  }

  private async initializeKeyboard() {
    if (this.platform.is('android')) {
      await Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
    
    // Set up keyboard listeners
    Keyboard.addListener('keyboardWillShow', (info: any) => {
      const activeElement = document.activeElement as HTMLElement;
      
      if (this.platform.is('ios')) {
        // For iOS, adjust the viewport height to include keyboard
        document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - info.keyboardHeight}px`);
        document.body.style.height = `${window.innerHeight - info.keyboardHeight}px`;
      }
      
      if (activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA') {
        setTimeout(() => {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    });

    Keyboard.addListener('keyboardWillHide', () => {
      if (this.platform.is('ios')) {
        // Reset viewport height
        document.documentElement.style.setProperty('--viewport-height', '100vh');
        document.body.style.height = '100vh';
      }
    });
  }
}