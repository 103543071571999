import { registerPlugin, WebPlugin } from '@capacitor/core';
const LiveUpdates = registerPlugin('LiveUpdates', {
  web: () => new LiveUpdatesWeb()
});
export const sync = async (progress = undefined) => {
  return new Promise((resolve, reject) => {
    LiveUpdates.sync({}, result => {
      if ('progress' in result) {
        if (progress) {
          progress(result.progress);
        }
      } else if ('failStep' in result) {
        reject(result);
      } else {
        resolve(result);
      }
    });
  });
};
export const setConfig = async config => {
  return LiveUpdates.setConfig(config);
};
export const getConfig = async () => {
  return LiveUpdates.getConfig();
};
export const resetConfig = async () => {
  return LiveUpdates.resetConfig();
};
export const reload = async () => {
  return LiveUpdates.reload();
};
class LiveUpdatesWeb extends WebPlugin {
  async sync(_options, callback) {
    callback({
      failStep: 'CHECK',
      message: 'Not implemented for web only'
    });
    return '';
  }
  /* eslint-disable */
  //@ts-ignore
  async setConfig(config) {}
  async getConfig() {
    return {};
  }
  async resetConfig() {}
  async reload() {}
}
export * from './definitions';
