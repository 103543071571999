import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from "./app/app.config";
import { enableProdMode, mergeApplicationConfig } from '@angular/core';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { environment } from 'environment/environment';
import { type } from 'os';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

if (environment.production) {
    enableProdMode();
  }
document.addEventListener('DOMContentLoaded', () => {

bootstrapApplication(AppComponent, 
    mergeApplicationConfig(appConfig, {
        providers: [
            provideMessaging(() => getMessaging())
        ]
    }, {
  providers: [provideIonicAngular({})]
}
// , 
// {
//   providers: [provideFirebaseApp(() => initializeApp({"projectId":"pdcapp-acf0f","appId":"1:1015872408157:web:1dc8a65c344e371ad95bb4","storageBucket":"pdcapp-acf0f.appspot.com","apiKey":"AIzaSyACjT9Gih13reYWMW5X2-SEsMtETulWIzE","authDomain":"pdcapp-acf0f.firebaseapp.com","messagingSenderId":"1015872408157","measurementId":"G-9L9SMYBFER"}))]
// }, {
//   providers: [provideMessaging(() => getMessaging())]
// }
, {
  providers: [provideFirebaseApp(() => initializeApp({"projectId":"pdcapp-acf0f","appId":"1:1015872408157:web:1dc8a65c344e371ad95bb4","storageBucket":"pdcapp-acf0f.appspot.com","apiKey":"AIzaSyACjT9Gih13reYWMW5X2-SEsMtETulWIzE","authDomain":"pdcapp-acf0f.firebaseapp.com","messagingSenderId":"1015872408157","measurementId":"G-9L9SMYBFER"}))]
}, {
  providers: [provideMessaging(() => getMessaging())]
}))  .then(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register('firebase-messaging-sw.js', {type:'module'})
            .then((registration) => {
              console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
              console.error('Service Worker registration failed:', error);
            });
        }
      }).catch((err) =>
    console.error(err)
)
})


// ,
//                       "server": "src/main.server.ts",
//                       "prerender": false,
//                       "ssr": {
//                         "entry": "server.ts"
//                       }