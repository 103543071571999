<div class="flex min-w-0 flex-auto flex-col">
  <div class="bg-card flex flex-0 flex-col border-b p-6 dark:bg-transparent sm:flex-row sm:items-center sm:justify-between sm:px-10 sm:py-8">
      <div class="min-w-0 flex-1">
          <div class="flex flex-wrap items-center font-medium">
              <div class="whitespace-nowrap text-primary-500">Legal</div>
              <div class="ml-1 flex items-center whitespace-nowrap">
                  <span class="ml-1 text-primary-500">Privacy Policy</span>
              </div>
          </div>
          <div class="mt-2">
              <h1 class="truncate text-3xl font-extrabold leading-7 tracking-tight sm:leading-10 md:text-4xl">Privacy Policy</h1>
          </div>
      </div>
  </div>

  <div class="prose prose-sm max-w-3xl flex-auto p-6 sm:p-10">
      <section class="mb-8">
          <h2 class="text-2xl font-semibold mb-4">Information Collection</h2>
          <p>We collect information that you provide directly to us, including:</p>
          <ul>
              <li>Personal information (name, email address)</li>
              <li>Usage data and preferences</li>
              <li>Device and browser information</li>
          </ul>
      </section>

      <section class="mb-8">
          <h2 class="text-2xl font-semibold mb-4">Data Usage</h2>
          <p>We use the collected information for:</p>
          <ul>
              <li>Service provision and maintenance</li>
              <li>User experience improvement</li>
              <li>Updates and notifications</li>
              <li>Analytics and monitoring</li>
          </ul>
      </section>

      <section class="mb-8">
          <h2 class="text-2xl font-semibold mb-4">Security Measures</h2>
          <p>We implement industry-standard security measures to protect your personal information.</p>
      </section>


      

      <section class="mb-8">
          <h2 class="text-2xl font-semibold mb-4">Contact Information</h2>
          <p>For privacy-related inquiries:</p>
          <p>{{"Email: privacy@pdcsafetrack.online"}}</p>
          <p>KSA: King Abdulaziz Road, Abha, 66231</p>
      </section>

      <footer class="text-sm text-gray-600">
          <p>Last updated: {{lastUpdated | date:'longDate'}}</p>
      </footer>
  </div>
</div>