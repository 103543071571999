import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  standalone:true,
  imports: [MatIconModule, MatButtonModule, DatePipe],

})
export class PrivacyComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}
   // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Last updated date
     */
    lastUpdated: Date = new Date();
}

 
